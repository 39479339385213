@import "../../../../styles/variables";
@import "../../../../styles/global";

.dateRanges {
  display: flex;
}

.dateSelect {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
    margin: 8px 0;
  }
}

.fieldInputSmall {
  background-color: $color-grey0;
  width: 340px;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 8px 10px;
}

.dateInput{
  display: flex;
  align-items: center;
}

.dateLink {
  color: $color-grey4;
  text-decoration: underline;
  margin-left: 20px;

  &:hover {
    color: $color-blue4;
    cursor: pointer;
  }
}

.clearLink {
  color: $color-grey4;
  text-decoration: underline;
  margin-left: 20px;

  &:hover {
    color: $color-blue4;
    cursor: pointer;
  }
}

input {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
