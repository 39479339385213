@import "../../../../styles/variables";
@import "../../../../styles/global";

.pageContent {
  padding: 50px 40px;
  width: 100%;
  font-size: $font-size8;
  color: $color-grey4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 200px;
}

.loading{
  font-size: $font-size9;
}
