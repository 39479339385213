@import "../../../styles/variables";
@import "../../../styles/mixins";

.searchResult {
  padding: 10px 20px;
  border-top: $border3;
  cursor: pointer;

  .resultName {
    @include text-ellipsis();
    color: $color-black;
  }

  .resultType {
    color: $color-grey5;
    margin-bottom: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:hover {
    background-color: $color-grey0;

    .resultType {
      color: $color-blue5;
    }

    .resultName {
      color: $color-black;
    }
  }
}
