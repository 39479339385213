@import "../../../styles/variables";
@import "../../../styles/global";

.pageContent {
  padding: 30px 40px;
  width: 100%;
  font-size: $font-size5;
}

.catalogItems {
  max-width: 800px;
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  max-height: 576px;
  overflow: auto;
}

.noReports {
  display: flex;
  max-width: 800px;
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;

  .iconPanel {
    color: $color-blue4;
    padding: 0 20px;
    @include center-flex(flex);
  }

  .textPanel {
    line-height: 22px;
  }
}
