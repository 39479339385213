@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  max-height: 500px;
  min-height: 500px;
  overflow-y: auto;
  margin-right: -40px;
  padding-right: 40px;
}
