@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.soldToSelectionControl {
  display: flex;
  align-items: center;
  width: 320px;
  height: 40px;
  background-color: $color-grey0;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 8px 10px;
  cursor: pointer;
}

.soldToDownloadStructure {
  display: flex;
}

.soldToDivider{
  margin: 0 5px;
}

.soldToLinks {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.soldToLink {
  display: flex;
  align-items: center;
  color: $color-blue4;
  cursor: pointer;

  &:hover{
    color: $color-blue5;
  }
}
