@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.chartView {
  height: 100%;
  width: 100%;
}

.chartHeader {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 40px;
  border-bottom: $border2;
  font-size: $font-size6;
  display: flex;
  padding-left: 30px;
  align-items: center;
  justify-content: space-between;

  .headerTitle {
    font-weight: bolder;
  }
}

.interactions {
  .interaction {
    font-size: $font-size5;
    border-radius: 10px;
    height: 26px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border: $border2;
    cursor: pointer;

    .icon {
      margin-left: 10px;
      margin-top: 3px;
    }

    &:hover {
      background-color: $color-grey1;
    }
  }
}

.chartContent {
  position: relative;
  padding: 20px;
  display: flex;
  height: calc(100% - 40px);
}

.tableContent{
  height: calc(100% - 40px);
}

.headerToggle {
  @include center-flex(flex);
  border-left: $border2;
  width: 40px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey1;
  }
}

