@import "../../../styles/variables";
@import "../../../styles/global";

.redrawContainer {
  position: absolute;
  padding-top: 20px;
  height: 100%;
  width: 100%;
}

.redrawMask {
  cursor: pointer;
  z-index: 1;
  opacity: 0.35;
  position: absolute;
  background-color: $color-grey6;
  height: 100%;
  width: 100%;
}

.redrawBar {
  @include no-select();
  height: 40px;
  width: 100%;
  background-color: $color-blue5;
  text-align: center;
  color: white;
  line-height: 40px;
}

.redrawCaption {
  margin-top: 80px;
  color: white;
  font-size: $font-size8;
  text-align: center;
}

.redrawVisuals {
  cursor: pointer;
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
}

.captionText {
  margin-left: 20px;
}
