@import "../../../../styles/variables";
@import "../../../../styles/global";

.dashboardContainer {
  min-height: 100%;
  flex-grow: 1;
  position: relative;
}

.widgetsPanel {
  padding: 10px 20px;
}

.redrawMask {
  cursor: pointer;
  z-index: 200;
  opacity: 0.3;
  position: absolute;
  background-color: $color-grey6;
  height: 100%;
  width: 100%;
}

.redrawVisuals {
  cursor: pointer;
  z-index: 200;
  position: absolute;
  height: 100%;
  width: 100%;
  color: white;
  font-size: $font-size8;
  margin-top: 50px;
  text-align: center;
}

.gridCell {
  position: relative;
}
