@import "../../styles/variables";
@import "../../styles/global";
@import "../../styles/mixins";

.popupContainer {
  min-width: 780px;
  max-width: 880px;
  padding: 30px;
  overflow: auto;
}

.title {
  font-size: $font-size8;
  font-weight: bolder;
  margin-bottom: 30px;
}

.buttonsPanel {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
