@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";
@import "vehicle-configuration-view.module";

.axle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  &.single {
    height: calc(#{$tireHeight} + (1 * #{$connectorHeight}) + (1 * #{$tireInfoHeight}));
  }

  &.double {
    height: calc(#{$tireHeight} + (2 * #{$connectorHeight}) + (2 * #{$tireInfoHeight}));
  }
}

.axleInfo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(#{$tireHeight/2 - $axleInfoHeight/2} + (1 * #{$connectorHeight}) + (1 * #{$tireInfoHeight}));
  left: calc(50% - (#{$axleInfoWidth}/2));
  height: $axleInfoHeight;
  width: $axleInfoWidth;
  font-size: $font-size6-5;
  font-weight: bold;
  color: $color-grey7;
  background-color: $color-white;
  border:$axleThickness solid $color-grey7;
  border-radius: 50%;
}

.axleGroup {
  display: flex;
}

.horizontalAxle {
  position: absolute;
  height: $axleThickness;
  left: 2px;
  right: 2px;
  background-color: $color-grey7;
  top: calc(#{$tireHeight/2 - $axleThickness/2} + (1 * #{$connectorHeight}) + (1 * #{$tireInfoHeight}));
}
