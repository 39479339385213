@import "../../styles/variables";
@import "../../styles/global";
@import "../../styles/mixins";

.title {
  font-size: $font-size8;
  font-weight: bolder;
  margin-bottom: 30px;
}

.divider {
  margin: 30px 0;
}

.popupContainer {
  min-width: 980px;
  max-width: 1400px;
  height: calc(100vh - 300px);
  padding: 30px;
  overflow: auto;
}

.confirmButton {

}

.buttonsPanel {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
