@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";


.popupContainer {
  width: 460px;
  height: 200px;
}

.popupHeader {
  height: 40px;
}

.popupBody {
  padding-top: 10px;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.fieldInput {
  background-color: $color-grey0;
  width: 80%;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 8px 10px;
}

.caption {
  text-align: left;
  width: 80%;
  margin-bottom: 15px;
  font-size: $font-size5;
}

.buttons {
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.button {
  margin: 0 10px;
}
