@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.chartView {
  padding: 20px 35px 28px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .chartColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .infoSecondary {
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .labelSecondary {

      }

      .valueSecondary {
        font-weight: bold;
        margin-left: 10px;
      }
    }

    .infoText {
      font-size: $font-size4;
      color: $color-grey5;
      font-style: italic;
    }

    &:not(:first-of-type) {
      margin-left: 5px;
      width: 100%;
    }

    .chartRow {
      min-width: 215px;
    }
  }
}

.titlePanel {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  font-size: $font-size4;
}

.valuePanel {
  display: flex;
  padding-left: 20px;
  padding-top: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 4px solid $color-blue4;
}

.ratioValues {
  display: flex;
}

.label {
  @include center-flex(flex);
  font-size: $font-size4;
  margin-top: 5px;
  color: $color-grey5
}

.value {
  @include center-flex(flex);
  font-size: $font-size7;
  font-weight: bolder;
}

.comparisonValue {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  font-size: $font-size3;
  margin-left: 10px;
}

.positive {
  color: $color-green;
}

.negative {
  color: $color-red;
}

.unit {
  margin-left: 5px;
}
