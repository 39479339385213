@import "../../../styles/variables";
@import "../../../styles/mixins";

.pageContainer {
  @include center-flex-x(flex);
  align-items: flex-start;
  padding: 100px;
  height: calc(100vh);
  height: -moz-calc(100vh);
  height: -webkit-calc(100vh);
  min-height: 560px;
  background-color: $color-white;
  width: 100%;
  overflow: auto;

  background-image: url('../../../assets/images/truck-image.jpg');
  background-size: cover;
  background-position: center;

  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 40px;

    @media only screen and (max-width: 1140px) {
      display: none;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 30px;
}

.footerPanel {
  margin-top: 20px;

  .knowMore {
    .highlight {
      color: $color-blue5;
      cursor: pointer;
    }
  }
}

.selectionButtons {
  margin: 10px 0;
}

.formPanel {
  display: flex;
  flex-direction: column;
  width: 300px;

  .title {
    font-size: $font-size9;
    font-weight: 200;
  }

  .subtitle {
    font-size: $font-size8;
    padding: 10px 0 0 0;
    font-weight: 600;
  }

  .caption {
    font-size: $font-size6;
  }

  .form {
    margin: 10px 0;

    .field {
      margin: 15px 0;

      .fieldLabel {
        padding-bottom: 10px;
        font-size: $font-size5;
      }

      .fieldInput {
        height: 40px;
        line-height: 50px;
        width: 100%;
        border-radius: 10px;
        outline: 0;
        border: $border2;
        font-size: $font-size6;
        padding: 0 10px;
        background-color: $color-grey0;

        &:disabled {
          opacity: 0.6;
        }
      }
    }

    .errorMessage {
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      color: $color-red4;
    }
  }
}

.forgotPassword {
  margin-top: 15px;
  color: $color-blue5;
  cursor: pointer;
  height: 30px;
  font-size: $font-size5;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: $font-size6;
  height: 36px;
  background: linear-gradient(0deg, $color-blue6 0%, $color-blue7 100%);
  width: 100%;
  color: $color-white;
  border: 1px solid $color-blue6;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:focus {
    outline: none;
  }

  &Inactive {
    pointer-events: none;
    opacity: 0.7;
  }

  &Disabled {
    pointer-events: none;
  }

  &Higher {
    height: 60px;
    margin-top: 30px;
  }
}
