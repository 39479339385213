@import "../../../styles/variables";
@import "../../../styles/mixins";

.popupContainer {
  @include no-select;
  width: 1000px;
  height: 640px;
}

.loadingContainer {
  text-align: left;
  padding: 30px;
  display: flex;

  .caption {
    margin-left: 10px;
  }
}

.popupContent {
  text-align: left;
  min-height: 100%;
}

.contentTitle {
  padding: 15px 30px;
  min-height: 80px;

  .type {
    color: $color-grey7;
    font-size: $font-size5;
    margin-bottom: 5px;
  }

  .id {
    font-size: $font-size7;
  }
}

.addressContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;

  .label {
    width: 100px;
    text-align: right;
    color: $color-grey7;
    padding-right: 10px;
  }
}

.contentBody {
  height: 560px;
  border-top: 1px solid $color-grey2;
  background-color: $color-grey0;
  padding: 20px 40px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.chartTitle {
  margin-top: 30px;
  font-size: $font-size6;
  text-align: center;
  font-weight: bolder;
}

.chartContainer {
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
}

.chartContent {
  position: relative;
  padding: 20px;
  display: flex;
  height: 360px;
}

.field {
  margin-bottom: 10px;
  display: flex;

  .label {
    width: 100px;
    text-align: right;
    color: $color-grey7;
    padding-right: 10px;
  }

  .value {
    width: 280px;
  }
}

.loading {
  padding-top: 40px;
  display: flex;
  min-width: 800px;
  justify-content: center;
}
