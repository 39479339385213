@import "../../../styles/variables";
@import "../../../styles/mixins";

.sidebarItemContainer {
  @include center-flex(flex);
  flex-direction: column;
  width: 100%;
  height: 60px;
  cursor: pointer;

  &.sidebarItemActiveContainer {
    background-color: $color-blue6;
  }

  &:hover {
    background-color: $color-blue5;
  }

  &.sidebarItemActiveContainer, &:hover {
    .sidebarTextPanel {
      color: $color-white;
    }

    .sidebarIconPanel {
      .sidebarIcon {
        fill: $color-white;
      }
    }
  }
}

.sidebarIconPanel {
  height: 26px;
  @include center-flex(flex);
  width: 100%;

  .sidebarIcon {
    height: 26px;
    fill: $color-grey4;
    -webkit-transition: height 0.5s; /* Safari prior 6.1 */
    transition: height 0.5s;
  }
}

.sidebarTextPanel {
  font-size: $font-size2;
  color: $color-grey4;
  @include center-flex(flex);
  display: none;
}


.sidebarIsOpen {
  height: 80px;

  & > .sidebarTextPanel {
    display: flex;
  }

  & > .sidebarIconPanel {
    height: 36px;

    & > .sidebarIcon {
      height: 36px;

    }
  }
}
