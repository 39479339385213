@import "../../../styles/variables";
@import "../../../styles/mixins";

.popupContainer {
  @include no-select;
  width: 1150px;
  height: 650px;
}

.loadingContainer {
  text-align: left;
  padding: 30px;
  display: flex;

  .caption {
    margin-left: 10px;
  }
}

.popupContent {
  text-align: left;
  min-height: 100%;
}

.contentTitle {
  padding: 15px 30px;
  min-height: 80px;

  .type {
    color: $color-grey7;
    font-size: $font-size5;
    margin-bottom: 5px;
  }

  .id {
    font-size: $font-size7;
  }
}

.contentBody {
  height: 520px;
  border-top: 1px solid $color-grey2;
  background-color: $color-grey0;
  padding: 20px 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.line {
  padding: 0 20px;
  display: flex;
  line-height: 40px;
  border-bottom: 1px solid $color-grey2;
  cursor: pointer;

  .dateField {}
  .jobTypeField {
    padding-left: 10px;
    flex-grow: 1
  }
  .netPriceField {}

  &.selected {
    background-color: $color-grey1;
  }

  &:hover {
    color:white;
    background-color: $color-blue4;
  }

  &:last-child {
    border-bottom: none;
  }
}

.linesContainer {
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  display: flex;
  min-height: 320px;
  margin-top: 10px;

  .lines {
    width: 50%;
    border-right: $border2;
    overflow-y: scroll;
    max-height: 395px;
  }

  .details {
    width: 50%;
    padding: 20px;
  }
}


.metaData {
  margin-bottom: 20px;
  display: flex;
}

.infoContainers {
  display: flex;
  flex-direction: row;
}

.infoContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.field {
  margin-bottom: 10px;
  display: flex;

  .label {
    width: 100px;
    text-align: right;
    color: $color-grey7;
    padding-right: 10px;
  }

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action {
    cursor: pointer;
    color: $color-blue4;

    &:hover {
      text-decoration: underline;
    }
  }
}
