@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  border: $border2;
  border-radius: 10px;
}

.tableHead {
  @include no-select();
  width: 100%;

  & > .tableRow {
    background-color: $color-grey1;
    border-radius: 10px 10px 0 0;
  }
}

.tableBody {
  width: 100%;
  background-color: white;
  .tableRows {
    & > .tableRow {
      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:nth-child(odd) {
        background-color: $color-white;
      }

      &:nth-child(even) {
        background-color: $color-grey1;
      }
    }
  }
}

.tableRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 20px;
}

.tableRowHeader {
  background-color: $color-grey1;
  border-bottom: $border2;
}

.tableRowLoading {
  height: 100%;
  border-radius: 0 0 10px 10px;
  @include center-flex(flex);
}
