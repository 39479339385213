@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.selectorContainer {
  display: flex;
  max-height: 100%;

  div:last-child {
    border-right: none;
  }
}

.selectorColumn {
  flex-grow: 1;
  flex-basis: 33%;
  border-right: $border2;
}
