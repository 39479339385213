@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.popupContainer {
  width: 460px;
  height: 170px;
}

.popupHeader {
  height: 40px;
  background: $color-grey2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popupBody {
  padding-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.caption {
  font-size: $font-size5;
  text-align: left;
}

.buttons {
  display: flex;
  padding-top: 25px;
}
