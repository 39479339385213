@import "../../../../styles/variables";
@import "../../../../styles/global";

.select {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
    margin: 8px 0;
  }
}
