@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.settingsPanel {
  @include no-select();
  border-left: 1px solid $color-grey2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 59px;
  cursor: pointer;
  line-height: 58px;
  color: $color-grey5;
  font-size: $font-size6;

  .settingsIcon {
    font-size: 18px;
  }

  &.isFirst {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: none;
  }

  &.isLast {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.isOpen {
    background-color: $color-grey1;
  }

  &.isAnyOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover {
    background-color: $color-grey1;
  }
}

