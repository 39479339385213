@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-grey4;
  opacity: 0.3;
}

.popupContainer {
  display: inline-block;
  position: relative;
  @include box-shadow(1);
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
