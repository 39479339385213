@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.searchPanel {
  @include transition-animation(0.2s);
  @include box-shadow(1);
  height: 0;
  font-size: $font-size5;
  z-index: 999;
  position: absolute;
  top: 40px;
  visibility: visible;
  display: flex;
  flex-direction: column;
  border: none;
  overflow: hidden;
  background-color: white;

  &.isOpen {
    height: 140px;
    width: 260px;
    border: $border3;
  }
}

.searchContent {
  padding: 15px;
}

.filterInput {
  outline: none;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid $color-grey4;
  font-size: $font-size5;
}

.buttons {
  margin-top: 15px;
}

.button {
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;
  line-height: 33px;
  border-radius: 5px;
  background-color: $color-blue4;
  color: $color-white;
  margin-right: 10px;
  min-width: calc(50% - 5px);

  &.invert {
    border: 1px solid $color-blue4;
    color: $color-blue4;
    background-color: $color-white;
  }

  &:hover {
    color: $color-white;
    background-color: $color-blue5;
  }
}
