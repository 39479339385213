@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.popupContainer {
  width: 680px;
  height: 100%;
  padding: 30px;
}

.widgetsGroup {

}

.widgetType {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $color-blue4;
  }
}


.groupSelectionBar {
  display: flex;
  font-size: $font-size5;
  justify-content: space-around;
  padding: 10px 0 20px 0;

  .groupLink {
    cursor: pointer;
    padding: 10px 0;

    &.active {
      border-bottom: 2px solid $color-blue4;
    }
  }
}
