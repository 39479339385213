@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.popupContainer {
  width: 1200px;
  text-align: left;
}

.popupHeader {
  padding: 15px 30px;
  min-height: 80px;
  border-bottom: $border2;

  .titleTop {
    color: $color-grey7;
    font-size: $font-size5;
    margin-bottom: 5px;
  }

  .title {
    font-size: $font-size7;
  }
}

.popupBody {
  position: relative;
  height: 710px;
  padding: 0 40px 20px;
  background-color: $color-grey0;
}

.tabsContainer {
  @include no-select();
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-between;
  border-bottom: $border2;
}

.tabs {
  display: flex;
  max-width: calc(100% - 160px);
}

.tab {
  @include center-flex(flex);
  height: 100%;
  display: flex;
  justify-content: center;
  width: 220px;
  padding: 10px 0 0 10px;
  cursor: pointer;
  color: $color-grey5;

  &:hover {
    color: $color-blue6;
  }

  .caption {
    @include text-ellipsis();
    max-width: 190px;
    text-align: center;
    flex-grow: 1;
  }
}

.activeTab {
  cursor: default;
  color: $color-blue6;
  font-weight: 600;
  border-bottom: 2px solid $color-blue6;
}

.buttons {
  position: absolute;
  bottom: 40px;
  display: flex;

  .button {
    margin-right: 20px;
    width: 200px;
  }
}
