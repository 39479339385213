@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.chartView {
  padding-left: 35px;
  height: 100%;
  width: 100%;
}

.infoIcon {
  color: $color-grey3;
  margin-left: 10px;
}

.tooltip {
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
  width: 300px;
}

.titlePanel {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: $font-size4;
}

.valuePanel {
  display: flex;
  padding-left: 20px;
  padding-top: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 4px solid $color-blue4;
}

.label {
  @include center-flex(flex);
  font-size: $font-size4;
  margin-top: 5px;
  color: $color-grey5
}

.value {
  @include center-flex(flex);
  font-size: $font-size7;
  font-weight: bolder;
}

.unit {
  margin-left: 5px;
}
