@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.content {
  padding: 0 30px;
  height: 100%;
  overflow-y: auto;
}

.contentPanel {
  background-color: white;
  border: $border2;
  border-radius: 10px;
  min-height: 400px;
  max-width: 1400px;
  padding: 30px;
}

.loading {
  padding: 30px;
}

.topBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px;
}

.table {
  margin-top: 0;
  max-width: 1400px;
}

.rows {
  max-height: 450px;
  height: 450px;
  overflow: auto;
}

.row {
  height: 45px;
  display: flex;
  border-radius: 5px;
  border-bottom: 1px solid $color-grey1;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey1;
  }

  &.highlight {
    background-color: $color_red;
    color: $color_white;

    &:hover {
      background-color: $color_red6;
    }
  }

  &.header {
    font-weight: bolder;
    height: 40px;
    background-color: $color-white;
  }
}

.cell {
  flex: 1 1;
}

.alertCellSpacer {
  font-size: $font-size7;
  width: 30px;
}

.optionsCell {
  width: 30px;
  cursor: pointer;

  &:hover {
    .optionButton {
      border: 1px solid $color-grey3;
    }
  }
}

.searchContainer {
  width: 40%;
  background-color: $color-grey0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: $border2;
}

.searchIcon {
  color: $color-grey4;
}

.searchInput {
  height: 30px;
  width: 90%;
  outline: none;
  border: none;
  background-color: $color-grey0;
  padding: 0 20px;
  font-size: $font-size6
}

.buttons {
  display: flex;
  margin-top: 30px;
}

.paging {
  max-width: 1400px;
  margin-top: 10px;
}

.noData {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
