@import "../../../styles/variables";
@import "../../../styles/global";

.initializingPanel {
  padding: 30px 60px;
}

.pageContent {
  overflow-y: scroll;

  &.isEdit {
    background-color: $color-grey1;
    padding-bottom: 60px;
  }
}

.scrollPanel {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loadingPanel {
  padding: 40px 60px;
  color: $color-grey5;
}

.createPanel {
  padding: 40px 60px;
  color: $color-grey5;

  .createLink {
    margin-top: 10px;
    color: $color-blue4;
    cursor: pointer;

    &:hover {
      color: $color-blue5;
    }
  }
}
