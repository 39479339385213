@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.pageContent {
  padding: 30px 40px;
  width: 100%;
  font-size: $font-size5;
}

.addNewScheduleButton {
  @include center-flex(inline-flex);
  height: 40px;
  background-color: $color-blue4;
  color: $color-white;
  min-width: 200px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: $font-size5;

  &:hover {
    background-color: $color-blue5;
  }
}
