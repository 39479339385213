@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

$verticalPadding: 20px;
$tireWidth: 55px;
$tireHeight: 100px;
$axleThickness: 8px;
$connectorHeight: 10px;
$tireInfoHeight:40px;
$tireInfoMaxWidth:260px;
$connectorColor: $color-grey4;
$axleInfoWidth:46px;
$axleInfoHeight:46px;

.viewerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 650px;
  padding: 0 10px;
  gap: 15px;
}

.vehicleBase {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalAxle {
  position: absolute;
  background-color: $color-grey7;
  top: calc(#{$verticalPadding} + #{$tireInfoHeight} + #{$connectorHeight} + #{$tireHeight / 2});
  bottom: calc(#{$verticalPadding}  + #{$tireHeight / 2});
  left: calc(50% - (#{$axleThickness}/2));
  width: $axleThickness;

  &.lastAxisDoubleTires{
    bottom: calc(#{$verticalPadding} + #{$tireInfoHeight} + #{$connectorHeight} + #{$tireHeight / 2});
  }
}

.axlesContainer {
  width: 100%;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: $verticalPadding $verticalPadding 0 $verticalPadding;
  gap: 5px;
}

.sparesBase {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sparesContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $verticalPadding $verticalPadding $verticalPadding;
}

.spareTire {
}
