@import "../../../../../styles/variables";
@import "../../../../../styles/global";

.pageContent {
  padding: 30px 40px;
  width: 100%;
  font-size: $font-size5;
  overflow-y: auto;
}

.form {
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;
  max-width: 1200px;
}

.buttons {
  display: flex;
  margin-top: 30px;

  .button {
    outline: none;
    margin-right: 20px;
  }
}

.info {
  margin: 0 10px 20px 0;
}

.select {
  margin: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
  }
}
