@import "../../../../styles/variables";
@import "../../../../styles/global";

.select {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
    margin: 8px 0;
  }
}

.selectorPanel {
  height: 0;
  width: 100%;
  @include transition-animation(0.4s);
  background-color: $color-grey0;
  border-radius: 10px;
  overflow: hidden;
  border: $border2;
  height: 200px;
  margin-bottom: 30px;
}
