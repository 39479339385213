@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/global";

.title {
  padding: 20px 0 10px;
  color: $color-grey5;
}

.form {
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.info {
  margin-bottom: 20px;
}

.fieldInput {
  background-color: $color-grey0;
  width: 100%;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 8px 10px;
}
