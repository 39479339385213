@import "../../../../styles/variables";
@import "../../../../styles/global";

.widgetEditPanel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
}

.deleteButton {
  @include center-flex(inline-flex);
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 1px solid $color-red;
  color: $color-red;
  background-color: white;
  border-radius: 30px;

  &:hover {
    background-color: $color-red;
    color: white;
  }
}
