@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.adminTile {
  cursor: pointer;
  width: 240px;
  height: 160px;
  background-color: white;
  border: $border2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $font-size6;

  &:hover {
    background-color: $color-grey1;
  }

  &.isDisabled {
    background: $color-grey2;
    cursor: default;
    color: $color-grey6;
  }
}

.adminTiles {
  padding: 0 30px;
  display: flex;
  gap: 20px;
}

.adminIcon {
  font-size: $font_size10;
  padding-bottom: 10px;
  color: $color-blue4;
}
