@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.domain {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.fieldInput {
  display: flex;
  width: 320px;
  height: 40px;
  background-color: $color-grey0;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: 14px;
  padding: 8px 10px;
}

.action{
  display: flex;
  color: $color-blue4;
  cursor: pointer;
  width: 30px;
  justify-content: center;
}
