//==================================//
//============= COLORS =============//
//==================================//
$color-black: #000000;
$color-white: #ffffff;

// Primary
$color-blue1: #DCEEFB;
$color-blue2: #B6E0FE;
$color-blue3: #84C5F4;
$color-blue4: #62B0E8;
$color-blue5: #4098D7;
$color-blue6: #2680C2;
$color-blue7: #186FAF;
$color-blue8: #0F609B;
$color-blue9: #0A558C;
$color-blue10: #003E6B;

$color-yellow1: #FFFBEA;
$color-yellow2: #FFF3C4;
$color-yellow3: #FCE588;
$color-yellow4: #FADB5F;
$color-yellow5: #F7C948;
$color-yellow6: #F0B429;
$color-yellow7: #DE911D;
$color-yellow8: #CB6E17;
$color-yellow9: #B44D12;
$color-yellow10: #8D2B0B;

// Neutrals
$color-grey0: #FAFBFB;
$color-grey1: #F0F4F8;
$color-grey2: #D9E2EC;
$color-grey3: #BCCCDC;
$color-grey4: #9FB3C8;
$color-grey5: #829AB1;
$color-grey6: #627D98;
$color-grey7: #486581;
$color-grey8: #334E68;
$color-grey9: #243B53;
$color-grey10: #102A43;

// Supporting
$color-cyan1: #E0FCFF;
$color-cyan2: #BEF8FD;
$color-cyan3: #87EAF2;
$color-cyan4: #54D1DB;
$color-cyan5: #38BEC9;
$color-cyan6: #2CB1BC;
$color-cyan7: #14919B;
$color-cyan8: #0E7C86;
$color-cyan9: #0A6C74;
$color-cyan10: #044E54;

$color-red1: #FFEEEE;
$color-red2: #FACDCD;
$color-red3: #F29B9B;
$color-red4: #E66A6A;
$color-red5: #D64545;
$color-red6: #BA2525;
$color-red7: #A61B1B;
$color-red8: #911111;
$color-red9: #780A0A;
$color-red10: #610404;

$color-red: #de5a41;
$color-green: #57cc3f;

$color-action: $color-blue4;
$color-action-disabled: $color-grey3;

//==================================//
//========== FONT SIZES ============//
//==================================//

$font-size1: 8px;
$font-size2: 10px;
$font-size3: 11px;
$font-size4: 12px;
$font-size5: 14px;
$font-size6: 16px;
$font-size6-5: 18px;
$font-size7: 21px;
$font-size8: 24px;
$font-size9: 36px;
$font-size10: 42px;

//==========================================//
//========== BORDERS ============//
//==========================================//
$border3: 1px solid $color-grey3;
$border2: 1px solid $color-grey2;

//=========================//
//======= SHADOWS =========//
//=========================//
$shadow1: 0px 3px 5px rgba(0, 0, 0, 0.1);
$shadow2: 0px 3px 15px rgba(0, 0, 0, 0.2);

//=========================//
//======= BORDER RADIUS =========//
//=========================//
$standard-border-radius: 10px;
