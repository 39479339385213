@import "../../../styles/variables";
@import "../../../styles/mixins";

.loggedInUserContainer {
  @include no-select();
  padding: 20px;

  .loggedInUserPanel {
    display: flex;
    padding: 10px 20px;
    line-height: 20px;
    cursor: pointer;
    border-radius: 10px;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: $color-grey1;

      .avatarSvg {
        fill: $color-grey1;
      }
    }
  }

  .textFields {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .emailField {
      @include text-ellipsis();
      text-align: right;
      max-width: 300px;
    }

    .accountField {
      @include text-ellipsis();
      max-width: 300px;
      text-align: right;
      color: $color-grey4;
    }
  }

  .avatar {
    margin-left: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $color-blue6;

    .avatarSvg {
      position: relative;
      fill: white;
      top: 5px;
      width: 48px;
      height: 48px;
    }
  }
}

.profilePanel {
  z-index: 1001;
  position: absolute;
  top: 90px;
  right: 20px;
  visibility: hidden;

  &.isOpen {
    visibility: visible;
  }
}
