@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";
@import "vehicle-configuration-view.module";

.tireGroup {
  display: flex;
  flex-direction: column;

  &.left {
    align-items: flex-start;
  }

  &.right {
    align-items: flex-end;
  }
}

.header {
  position: relative;
  max-width: $tireInfoMaxWidth;
  height: $tireInfoHeight;
  font-size: $font-size3;
  margin-bottom: $connectorHeight;

  display: flex;
  align-items: center;
  padding: 5px;

  &.hasInfo {
    border: 1px solid $connectorColor;
    border-radius: 5px;
  }
}

.headerText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.footer {
  position: relative;
  max-width: $tireInfoMaxWidth;
  height: $tireInfoHeight;
  font-size: $font-size3;
  margin-top: $connectorHeight;

  display: flex;
  align-items: center;
  padding: 5px;

  &.hasInfo {
    border: 1px solid $connectorColor;
    border-radius: 5px;
  }
}

.footerText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.tires {
  position: relative;
  display: flex;
  gap: 5px
}

.tire {
}
