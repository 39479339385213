@import "./variables";
@import "./mixins";
@import "../assets/fonts";

//============================//
//======= BODY HTML ==========//
//============================//
* {
    @include border-box-sizing();
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    height: 100%;
    background-color: $color-white;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $font-size5;
    color: $color-black;
    padding: 0;
    margin: 0;
    min-height: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
}

.root {
    height: 100%;
}

.appWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 1200px;
}

.pageContent {
    width: 100%;
}

.fullHeight {
    height: 100%;
}

//============================//
//======== NO SELECT =========//
//============================//

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                           supported by Chrome and Opera */
}

//=========================================//
//========== PLACEHOLDER STYLE ============//
//=========================================//

*::-webkit-input-placeholder {
    font-size: $font-size5;
    color: $color-grey5;
    font-weight: 200;
}

*:-moz-placeholder {
    /* FF 4-18 */
    font-size: $font-size4;
    color: $color-grey5;
    font-weight: 200;
}

*::-moz-placeholder {
    /* FF 19+ */
    font-size: $font-size5;
    color: $color-grey5;
    font-weight: 200;
}

*:-ms-input-placeholder {
    /* IE 10+ */
    font-size: $font-size5;
    color: $color-grey5;
    font-weight: 200;
}

//====================================//
//========== SHARED STYLE ============//
//====================================//

.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pageContent {
    border-top: $border2;
    flex: 1 1;
    background-color: $color-grey0;
}

.pageMainContent {
    width: calc(100% - 150px);
    border-top: $border2;
    flex: 1 1;
    background-color: $color-grey0;
}

.popupButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 80%;

    &.centered {
        justify-content: center;
    }
}

.button {
    @include center-flex(flex);
    font-size: $font-size5;
    min-width: 140px;
    padding: 0 20px;
    height: 35px;
    text-align: center;
    background-color: $color-blue4;
    border: 1px solid $color-blue4;
    color: $color-white;
    cursor: pointer;
    border-radius: 5px;
    outline: none;

    &:hover {
        background-color: $color-blue5;
        color: white;
    }

    &.isNegative {
        background-color: $color-white;
        color: $color-blue4;

        &:hover {
            background-color: $color-blue4;
            color: white;
        }
    }

    &:disabled {
        background-color: $color-action-disabled;
        border-color: $color-action-disabled;
        color: $color-white;
        cursor: not-allowed;

        &:hover {
            background-color: $color-action-disabled;
            border-color: $color-action-disabled;
        }

        &.isNegative {
            background-color: $color-white;
            color: $color-action-disabled;
        }
    }
}
