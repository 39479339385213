@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";
@import "tire-view.module";

.spareTireContainer {
  max-width: $tireInfoMaxWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spareTireContainerHeader {
  position: relative;
  max-width: $tireInfoMaxWidth;
  height: $tireInfoHeight;
  font-size: $font-size3;
  display: flex;
  align-items: center;
  padding: 5px;

  border: 1px solid $connectorColor;
  border-radius: 5px;
}

.spareTireContainerHeaderText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.spareTireTopConnectorWrapper {
  height: $connectorHeight;
  width: 100%;
  display: flex;
  justify-content: center;

  .spareTireTopConnector {
    height: $connectorHeight;
    width: 1px;
    background-color: $connectorColor;
  }
}
