@import "../../../styles/variables";
@import "../../../styles/mixins";

.popupContainer {
  width: 1250px;
  height: 750px;
}

.loadingContainer {
  text-align: left;
  padding: 30px;
  display: flex;

  .caption {
    margin-left: 10px;
  }
}

.popupContent {
  text-align: left;
  min-height: 100%;
}

.contentTitle {
  padding: 15px 30px;
  min-height: 80px;

  .type {
    color: $color-grey7;
    font-size: $font-size5;
    margin-bottom: 5px;
  }

  .info {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .id {
      font-size: $font-size7;
    }

    .tpmsIndicator {
      cursor: pointer;
      padding-top: 2px;
      .tpmsIcon {
        height: 22px;
        width: 22px;
        fill: $color-blue7;
      }
    }

    .dorIndicator {
      cursor: pointer;
      padding-top: 2px;
      .dorIcon {
        height: 22px;
        width: 22px;
        fill: $color-blue7;
      }
    }
  }

}

.infoContainers {
  display: flex;
  flex-direction: row;
}

.infoContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
}

.field {
  margin-bottom: 10px;
  display: flex;

  .label {
    width: 120px;
    text-align: right;
    color: $color-grey7;
    padding-right: 10px;
  }

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contentBody {
  height: 670px;
  border-top: 1px solid $color-grey2;
  background-color: $color-grey0;
  padding: 20px 40px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-y: scroll;
}

.configurationContainer {
  background-color: $color-white;
  border: $border2;
  border-radius: 10px 10px 0 10px;
  display: flex;
}

.configurationView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: $border2;
}

.configurationDetails {
  flex-grow: 1;
}

.loading {
  padding-top: 40px;
  display: flex;
  min-width: 800px;
  justify-content: center;
}

.jobsTitle {
  padding: 20px;
  font-weight: bolder;
}
