@import "../../../../../../styles/variables";
@import "../../../../../../styles/global";
@import "../../../../../../styles/mixins";

.popupContainer {
  width: 860px;
}

.popupHeader {
  height: 20px;
}

.popupBody {
  padding: 30px;
  height: calc(100% - 40px);
}

.selectionItems {
  border: $border2;
  margin-bottom: 15px;
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.emptyResults {
  height: 300px;
  line-height: 40px;
}

.popupBodyHeader {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  height: 50px;
  padding-bottom: 15px;
}

.title {
  display: flex;
  justify-content: flex-start;
  font-size: $font-size6;
}

.footnote {
  display: flex;
  justify-content: flex-end;
  font-size: $font-size5;
  color: $color-grey6;
}

.searchContainer {
  width: 50%;
  background-color: $color-grey0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: $border2;
}

.searchIcon {
  color: $color-grey4;
}

.searchInput {
  height: 30px;
  width: 90%;
  outline: none;
  border: none;
  background-color: $color-grey0;
  padding: 0 20px;
  font-size: $font-size6
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  align-items: center;

  .toolbarItem {
    display: flex;
    margin-right: 20px;
  }

  .link {
    @include no-select();
    padding: 0 5px;
    color: $color-action;
    cursor: pointer;

    &.active {
      color: $color-black;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}
