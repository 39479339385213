@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.chartView {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.headerPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: $font-size4;
}

.title {

}

.subtitle {
  font-size: $font-size4;
  margin-top: 5px;
  color: $color-grey5
}

.contentPanel {
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
}

.trafficLight {
  width: 40px;
  height: 40px;
  border-radius: calc(100% / 2);

  &.green {
    color: $color-green;
    // box-shadow: 0 0 10px 5px #2ecc71;
  }

  &.orange {
    color: $color-yellow5;
    // box-shadow: 0 0 10px 5px #f1c40f;
  }

  &.red {
    color: $color-red;
    // box-shadow: 0 0 10px 5px #c0392b;
  }
}

.graphContainer {
  margin-left: 40px;
  width: 320px;

  .barGraph {
    position: relative;
    width: 320px;
    height: 30px;
    border: $border2;
    border-radius: 15px;
    display: flex;
  }

  .greenSection {
    border-radius: 15px 0 0 15px;
    background-color: $color-green;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orangeSection {
    background-color: $color-yellow5;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .redSection {
    border-radius: 0 15px 15px 0;
    background-color: $color-red;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leftBorderRadius {
    border-radius: 15px 0 0 15px;
  }

  .rightBorderRadius {
    border-radius: 0 15px 15px 0;
  }

  .allBorderRadius {
    border-radius: 15px;
  }

  .sectionLabel {
    padding-top: 5px;
    text-align: center;
    font-size: $font-size4;
    width: 100%;
  }
}

.indicator {
  top: -10px;
  position: absolute;
  height: 40px;
  width: 3px;
  color: $color-white;
  background-color: $color-white;

  .icon {
    position: absolute;
    top: -10px;
    left: -12px;
  }
}

.questionIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $color-grey3;

  &:hover {
    color: $color-blue4;
    cursor: help;
  }
}

.infoTooltip {
  width: 260px;
}

.alertTooltip {
  width: 160px;
  text-align: center;
}

.legend {
  margin-top: 15px;
  display: flex;
}

.greenDot {
  height: 10px;
  width: 10px;
  background-color: $color-green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.orangeDot {
  height: 10px;
  width: 10px;
  background-color: $color-yellow5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.redDot {
  height: 10px;
  width: 10px;
  background-color: $color-red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
