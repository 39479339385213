@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/global';

.container {
    @include no-select();
    @include center-flex-y(flex);
    position: relative;
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.buttonPanel {
    display: inline-flex;
    border: 1px solid $color-grey1;
    padding: 5px;
    border-radius: 10px;
}

.menuContainer {
    min-width: 200px;
    cursor: default;
    position: absolute;
    top: -13px;
    right: 30px;
    border-radius: 10px;
    background-color: $color-white;
    color: $color-black;
    z-index: 3;
    border: 1px solid $color-grey3;
}

.menuItem {
    display: flex;
    align-items: center;
    height: 49px;
    cursor: pointer;
    padding: 0 20px;
    border-top: 1px solid $color-grey3;

    &.negative {
        color: $color-red;
    }

    &.positive {
        color: $color-green;
    }

    &:hover {
        background-color: $color-grey1;
    }

    &:first-child {
        border-top: none;
    }
}
