@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.tabsContainer {
  @include no-select();
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 100%;
  justify-content: space-between;
}

.dashboards {
  display: flex;
  max-width: calc(100% - 160px);
  width:100%
}

.actions {
  display: flex;
  max-width: 160px;
}
