@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.container {
  border-bottom: 1px solid $color-grey2;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: $color-grey1;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 10px 10px;

    .iconPanel {
      border-radius: 0 0 0 10px;
    }
  }

  &:first-child {
    border-radius: 10px 10px 0 0;

    .iconPanel {
      border-radius: 10px 0 0 0;
    }
  }
}

.iconPanel {
  @include center-flex(flex);
  font-size: 24px;
  background-color: $color-grey1;
  width: 60px;
  color: $color-grey6;
  border-right: $border2;
}

.reportLabels {
  padding: 15px 25px;

  .title {
    color: $color-black;
    font-size: $font-size6;
  }

  .subTitle {
    margin-top: 5px;
    color: $color-grey5;
  }
}
