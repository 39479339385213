@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.controlsContainer {
  margin: 0 10px;
  padding: 30px 30px 0px 30px;
}

.controlsContainerPanel {
  display: inline-flex;
  background-color: white;
  height: 60px;
  border: $border2;
  border-radius: 10px;

  &.isOpen {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.selectorPanel {
  height: 0;
  width: 800px;
  @include transition-animation(0.4s);
  background-color: white;
  border-radius: 10px;
  border-top-left-radius: 0;
  overflow: hidden;

  &.isOpen {
    border: $border2;
    height: 200px;
  }

  &.isLarge {
    width: 1200px;
  }
}
