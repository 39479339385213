@import "../../../styles/variables";
@import "../../../styles/mixins";

.toasterContainer {
    position: absolute;
    top: 120px;
    right: 32px;
    z-index: 20000;
    text-align: center;
    min-width: 350px;
    max-width: 350px;
    border-radius: 5px;

    .toast {
        position: relative;
        @include box-shadow(3);
        border-radius: 5px;
        max-height: 1000px;
        overflow: hidden;

        .toastTitle {
            @include center-flex(flex);
            position: relative;
            height: 35px;
            border-radius: 5px 5px 0 0;

            .toastClose {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        .toastMessage {
            background-color: $color-white;
            border-radius: 0 0 5px 5px;
            border: $border2;
            border-top: none;
            color: $color-black;

            .toastMessageText {
                @include center-flex(flex);
                padding: 0 20px 5px 20px;

                &:first-child {
                    padding-top: 20px;
                }

                &:last-child {
                    padding-bottom: 25px;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .toastSuccess {
        & > .toastTitle {
            background-color: $color-green;
            color: $color-white;
        }
    }

    .toastError {
        & > .toastTitle {
            background-color: $color-red5;
            color: $color-white;
        }
    }

    .toastInfo {
        & > .toastTitle {
            background-color: $color-blue5;
            color: $color-white;
        }
    }

    .toastWarning {
        & > .toastTitle {
            background-color: $color-yellow5;
            color: $color-white;
        }
    }
}






