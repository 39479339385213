@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.popupContainer {
  width: 460px;
  height: 160px;
}

.popupHeader {
  height: 40px;
}

.popupBody {
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.caption {
  margin-bottom: 10px;
  font-size: $font-size5;
  text-align: left;
}

.buttons {
  display: flex;
  padding-top: 20px;
}

.button {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}
