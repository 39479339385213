@import "../../../styles/variables";
@import "../../../styles/global";

.pageContent {
  padding: 20px;
}

.profilePanel {
  background-color: $color-white;
  padding: 30px;
  border: $border2;
  border-radius: 10px;
  max-width: 800px;
}

.titlePanel {
  display: flex;
  padding-bottom: 10px;

  .leftTitlePanel {
    padding: 0 10px 10px 0;
  }

  .rightTitlePanel {
    padding: 10px;
  }
}

.contentPanel {
  display: flex;
  flex-direction: column;
}

.initialsBox {
  @include center-flex(flex);
  background-color: $color-grey4;
  color: $color-white;
  font-size: $font-size8;
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.nameField {
  @include text-ellipsis();
  font-size: $font-size8;
}

.emailField {
  @include text-ellipsis();
  margin-top: 5px;
  color: $color-grey6;
  font-size: $font-size6;
}

.buttons {
  display: flex;
  margin-top: 15px;

  .button {
    margin-right: 20px;
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px 0;
  font-size: $font-size5;
}

.fieldRow{
  display: flex;
  align-items: center;
}

.fieldLabel {
  width: 150px;
  margin-right: 20px;
}

.fieldInfo {
  font-size: $font-size4;
  color: $color-grey5;
  margin-top: 10px;
}

