@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.header {
  display: flex;
  justify-content: space-between;
}

.titlePanel {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: $font-size7;
  margin-bottom: 10px;
}

.backLink {
  color: $color-blue4;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
}

.backIcon {
  margin-right: 10px;
}
