@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.tableColumn {
  @include text-ellipsis();
  flex: 1;
  padding-right: 20px;

  &.tableColumnSmall{
    flex: 0.75;
  }

  &.tableColumnTiny{
    flex: 0.50;
  }

  &.tableColumnCentered{
    text-align: center;
  }
}

.tableColumnHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;

  &:hover {
    text-decoration: underline;

    .filterIcon {
      visibility: visible;
    }
  }
}

.tableColumnLink {
  color: $color-blue5;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.tableColumnIcon {
  color: $color-grey5;
  margin-left: 5px;
}

.filterIcon {
  visibility: hidden;
  color: $color-grey5;
  margin-left: 5px;
  outline: none;

  :hover {
    color: $color-blue4;
  }

  &.isOpen {
    visibility: visible;
    color: $color-blue4;
  }

  &.alwaysVisible {
    visibility: visible;
    color: $color-blue4;
  }
}
