@import "../../../styles/variables";
@import "../../../styles/mixins";

.profilePanel {
  @include box-shadow(1);
  z-index: 9999;
  background-color: $color-white;
  width: 360px;
  border: $border3;
  border-radius: 10px;
  padding: 20px 30px;
}

.topPanel {
  display: flex;
  padding-bottom: 10px;

  .leftTopPanel {
    padding: 10px 10px 10px 0;
  }

  .rightTopPanel {
    padding: 10px;
  }
}

.menuItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;

  &:hover {
    color: $color-blue5;
  }

  .menuIcon {
    font-size: $font_size8;
    padding-right: 8px;
    color: $color-grey7;
  }
}

.initialsBox {
  @include center-flex(flex);
  background-color: $color-grey4;
  color: $color-white;
  font-size: $font-size8;
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.viewProfileButton {
  @include center-flex(inline-flex);
  height: 30px;
  background-color: $color-blue4;
  color: $color-white;
  min-width: 160px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  font-size: $font-size5;

  &:hover {
    background-color: $color-blue5;
  }
}

.nameField {
  @include text-ellipsis();
  width: 200px;
  font-size: $font-size6;
}

.emailField {
  @include text-ellipsis();
  width: 200px;
  color: $color-grey6;
  font-size: $font-size5;
}
