@import "../../../../../../styles/variables";
@import "../../../../../../styles/global";
@import "../../../../../../styles/mixins";

.soldToItem {
  @include no-select();

  display: flex;
  height: 40px;
  align-items: center;
  border-bottom: $border2;
  cursor: pointer;

  &.isDisabled {
    opacity: 0.7;
    pointer-events:none;
  }

  &:hover {
    background-color: $color-grey1;

    .indicator {
      background-color: $color-grey2;
    }
  }

  .item {
    padding-left: 30px;
    text-align: left;

    &.indicator {
      @include center-flex(flex);
      height: 40px;
      width: 40px;
      border-right: $border2;
      background-color: $color-grey1;
      padding-left: 0;
    }

    &.small {
      width: 100px;
    }

    &.medium {
      width: 140px;
    }

    &.large {
      width: 300px;
    }
  }
}
