@import "../../../styles/variables";
@import "../../../styles/global";

.editorBar {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: $color-grey4;
  color: white;
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.addAction {
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.saveAction {
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.deleteAction {
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

