@import "../../../styles/variables";
@import "../../../styles/mixins";

.headerContainer {
  @include box-shadow(1);
  z-index: 100;
  display: flex;
  height: 140px;
  width: 100%;
  background-color: $color-white;
  flex-direction: column;
}

.topContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .pageTitle {
    padding: 0 30px;
    font-size: $font-size8;
    color: $color-grey9;
    min-width: 400px;
  }

  .rightPanel {
    display: flex;
  }
}

.bottomContainer {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
