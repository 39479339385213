@import "../../../styles/variables";
@import "../../../styles/global";

.pageContent {
  overflow-y: auto;
}

.initializingPanel {
  padding: 30px 60px;
}
