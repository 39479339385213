@import "../../../styles/variables";
@import "../../../styles/global";

.pageContainer {
  height: calc(100vh);
  height: -moz-calc(100vh);
  height: -webkit-calc(100vh);
  min-height: 560px;
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
}

.leftContainer {
  flex: 1 1;
  background-image: url('../../../assets/images/login1.jpg');
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 1140px) {
    display: none;
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 30px;

    @media only screen and (max-width: 1140px) {
      display: none;
    }
  }
}

.rightContainer {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.notificationContainer {
  @include center-flex(flex);
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.notificationImage {
  display: flex;
  color: $color-blue4;
  margin-bottom: 60px;
}

.notificationContent {
  @include no-select();
  @include center-flex-x(flex);
  flex-direction: column;
  width: 100%;
}

.notificationTitle {
  @include center-flex-x(flex);
  text-align: center;
  font-size: $font-size9;
  font-weight: 200;
  padding: 0 60px;
  margin-bottom: 60px;
}

.notificationSubTitle {
  @include center-flex-x(flex);
  color: $color-grey4;
  font-size: $font-size8;
  font-weight: 200;
}
