@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.content {
  padding: 0 30px;
  height: 100%;
  overflow-y: auto;
}

.contentPanel {
  background-color: white;
  border: $border2;
  border-radius: 10px;
  max-width: 1400px;
  padding: 30px;
  margin-bottom: 30px;
}

.email {
  font-size: $font-size8;
}

.fields {
}

.vField {
  padding-bottom: 10px;

  .label {
    padding-bottom: 10px;
    color: $color-grey5;
  }

  .value {
    display: flex;
    align-items: center;
  }
}

.hField {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .label {
    min-width: 120px;
    text-align: right;
    padding-right: 10px;
    color: $color-grey5;
  }

  .value {
    display: flex;
    align-items: center;
  }
}

.buttons {
  display: flex;
  margin-top: 60px;
}

.button {
  margin-right: 20px;
}

.spacer {
  height: 40px;
}

.loading {
  padding: 40px;
}

.checkBoxItem {
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .checkBox {
    @include center-flex(flex);
    padding-right: 15px;
    font-size: $font-size6;
  }

  .checkBoxText {
    @include no-select();
    padding: 11px 0;
    color: $color-black;
  }

  &.disabled{
    pointer-events: none;
    opacity: 0.7;
  }
}
