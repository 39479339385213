@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.container {
    display: flex;
    justify-content: center;
    background-color: $color-grey0;
    height: 100%;
}

.panel {
    margin-top: 140px;
    padding: 30px 40px;
    text-align: center;
    font-size: $font-size6;
    background-color: $color-white;
    border: $border2;
    border-radius: 10px;
    height: 260px;
    line-height: 26px;
}

.icon {
    color: $color-red4;
    margin: 20px 0;
}

.link {
    margin: 30px 0;
    cursor: pointer;
    color: $color-blue4;

    &:hover {
        color: $color-blue5;
    }
}
