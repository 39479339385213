@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.yearContainer {
  min-width: 320px;
  max-width: 320px;
}

.yearPanel {
  @include center-flex(flex);
  height: 40px;

  .cycleButton {
    width: 60px;
    cursor: pointer;
    padding: 0 20px;

    &:hover {
      color: $color-blue4;
    }
  }

  .yearLabel {
    @include center-flex(flex);
  }
}

.monthsPanel {
  display: flex;
  flex-wrap: wrap;
}

.monthBox {
  @include center-flex(flex);
  @include no-select();
  margin: 3px 0;
  min-width: 80px;
  max-width: 50px;
  height: 40px;

  &:hover {
    border: $color-grey1 1px solid;
    cursor: pointer;
  }

  &:hover:not(.needsLeftCornerRadius):not(.needsRightCornerRadius):not(.isSelected) {
    border-radius: 10px;
  }

  &.isHovered {
    background-color: $color-grey1;
  }

  &.isSelected {
    background-color: $color-blue4;
    color: $color-white;
  }

  &.needsLeftCornerRadius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.needsRightCornerRadius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
