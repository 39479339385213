@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.widgetHeader {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 40px;
  border-bottom: $border2;
  font-size: $font-size6;
  display: flex;
  padding-left: 30px;
  align-items: center;
  justify-content: space-between;

  .headerTitle {
    font-weight: bolder;
  }
}

.widgetContent {
  height: calc(100% - 40px);
  position: relative;
}

.alertContainer {
  height: calc(100% / 5);
  border-bottom: $border2;
  display: flex;
  align-items: center;
  padding: 0 30px;

  &:last-child {
    border-bottom: none;
  }

  .label {
    font-size: $font-size5;
    @include text-ellipsis();
    width: 200px;
    padding-left: 30px;
  }

  .trafficLight {
    width: 30px;
    height: 30px;
    border-radius: calc(100% / 2);

    &.green {
      background-color: $color-green;
      // box-shadow: 0 0 10px 5px #2ecc71;
    }

    &.orange {
      background-color: $color-yellow5;
      // box-shadow: 0 0 10px 5px #f1c40f;
    }

    &.red {
      background-color: $color-red;
      // box-shadow: 0 0 10px 5px #c0392b;
    }
  }

  .graphContainer {
    width: 520px;
    padding-left: 100px;

    .barGraph {
      position: relative;
      width: 420px;
      height: 25px;
      border: $border2;
      border-radius: 15px;
      display: flex;
    }

    .greenSection {
      border-radius: 15px 0 0 15px;
      background-color: $color-green;
      height: 100%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .orangeSection {
      background-color: $color-yellow5;
      height: 100%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .redSection {
      border-radius: 0 15px 15px 0;
      background-color: $color-red;
      height: 100%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .boundary {
      position: absolute;
      font-size: $font-size4;
      top: 30px;
      margin-left: -15px;
    }
  }

  .indicator {
    top: -10px;
    position: absolute;
    height: 40px;
    width: 3px;
    color: $color-white;
    background-color: $color-white;

    .icon {
      position: absolute;
      top: -10px;
      left: -12px;
    }
  }

  .questionIcon {
    padding-left: 20px;
    color: $color-grey3;
  }
}
