@import "../../../../../styles/variables";
@import "../../../../../styles/global";

.pageContent {
  padding: 30px 40px;
  width: 100%;
  font-size: $font-size5;
  overflow-y: auto;
}

.form {
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.buttons {
  display: flex;
  margin-top: 30px;

  .button {
    outline: none;
    margin-right: 20px;
    margin-left: 0;
    height: 40px;
  }
}

.info {
  margin: 0 10px 20px 0;
}

.select {
  margin: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
    margin: 8px 0;
  }
}

.fieldInput {
  background-color: $color-grey0;
  width: 100%;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 8px 10px;
}
