@import "../../../styles/variables";
@import "../../../styles/mixins";

.sidebar {
    @include center-flex-x(flex);
    @include box-shadow(2);
    align-content: space-between;
    width: 60px;
    height: 100%;
    background: linear-gradient(0deg, $color-blue10 0%, $color-blue8 100%);
    font-size: $font-size4;
    color: $color-white;
    transition: width 0.3s ease-in-out;
    z-index: 1000;

    &.sidebarOpen {
        width: 140px;
    }
}

.logoPanel {
    height: 140px;
    padding-top: 20px;

    .logoSmall {
        width: 40px;
        height: 35px;
    }

    .logoBig {
        width: 100px;
    }
}

.topContainer {
    @include center-flex-x(flex);
    width: 100%;
}

.footerContainer {
    @include center-flex-x(flex);
    flex-direction: column;
    width: 100%;
    height: 200px;
}
