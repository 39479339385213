@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";
@import "vehicle-configuration-view.module";

$color-ok-dark:$color-black;
$color-ok-light:lighten($color-black,20);
$color-risk-dark:$color-yellow5;
$color-risk-light:lighten($color-yellow5,12);
$color-critical-dark:$color-red5;
$color-critical-light:lighten($color-red5,12);
$color-hover-dark:$color-blue7;
$color-hover-light:lighten($color-blue7,5);

.tireContainer {
  display: flex;
  flex-direction: column;
  height: calc(#{$tireHeight}px + #{2 * $connectorHeight}px);
}

.tire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $tireHeight;
  width: $tireWidth;
  border-radius: 10px;
  color: white;
  font-size: $font-size4;
  border: 4px solid transparent;

  background-image: url('../../../../../assets/icons/tire.svg');
  background-size: $tireWidth $tireHeight;
  background-position: center;

  cursor: pointer;

  &:hover {
    border: 4px solid $color-hover-dark;
  }

  &.risk{
    border: 4px solid $color-risk-dark;
    background-image: url('../../../../../assets/icons/tire-risk.svg');

    &:hover {
      border: 4px solid $color-risk-light;
    }
  }

  &.critical{
    border: 4px solid $color-critical-dark;
    background-image: url('../../../../../assets/icons/tire-critical.svg');

    &:hover {
      border: 4px solid $color-critical-light;
    }
  }
}

.tireInfoToolTip{
  display: flex;
  flex-direction: column;
  font-size: $font-size4;
  gap: 3px;
}

.icon {
  height: 16px;
  width: 16px;
  fill: $color-grey1;

  &.margin{
    margin-right: 3px;
  }
}

.value {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.label {
  width: 30px;
  margin: 10px 0;
  background-color: white;
  color: black;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
  font-size: $font-size4;
}

.topConnectorWrapper {
  position: absolute;
  top: -$connectorHeight;
  width: $tireWidth;
  display: flex;
  justify-content: center;

  .topConnector {
    height: $connectorHeight;
    width: 1px;
    background-color: $connectorColor;
  }
}

.bottomConnectorWrapper {
  position: absolute;
  bottom: -$connectorHeight;
  width: $tireWidth;
  display: flex;
  justify-content: center;

  .bottomConnector {
    height: $connectorHeight;
    width: 1px;
    background-color: $connectorColor;
  }
}
