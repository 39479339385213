@import "../../../../../../styles/variables";
@import "../../../../../../styles/global";
@import "../../../../../../styles/mixins";

.dropdown {
  display: flex;
  flex-direction: column;
  color: $color-black;
}

