@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.table {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  width: 100%;
  margin-bottom: 10px;
  border: $border2;
  border-radius: 10px;
  max-height: 576px;
  min-height: 80px;
  overflow: auto;
}

.tableHead {
  @include no-select();
  width: 100%;

  & > .tableRow {
    background-color: $color-grey1;
    border-radius: 10px 10px 0 0;
  }
}

.tableBody {
  width: 100%;
  background-color: white;
  border-radius: 0 0 10px 10px;

  .tableRows {
    & > .tableRow {
      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:nth-child(odd) {
        background-color: $color-white;
      }

      &:nth-child(even) {
        background-color: $color-grey1;
      }
    }
  }
}

.tableRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.tableRowHeader {
  background-color: $color-grey1;
  border-bottom: $border2;
}

.tableRowLoading {
  height: 80px;
  border-radius: 0 0 10px 10px;
  @include center-flex(flex);
}

.tableColumn {
  @include text-ellipsis();
  flex: 2;
  padding-left: 20px;
}

.tableColumnSmall {
  @include text-ellipsis();
  flex: 1;
  padding-left: 20px;
  text-align: center;
}

.tableColumnLink {
  color: $color-blue5;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px;

  &:hover {
    text-decoration: none;
  }
}
