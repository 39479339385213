@mixin center-flex($display) {
    display: $display;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin center-flex-x($display) {
    display: $display;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin center-flex-y($display) {
    display: $display;
    align-items: center;
    flex-wrap: wrap;
}

@mixin box-shadow($level) {
    @if $level == 1 {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.1);
    } @else if $level == 2 {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.5);
    }
}

@mixin border-box-sizing() {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@mixin text-ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin no-select() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin transition-animation($duration) {
    -moz-transition: height $duration ease;
    -webkit-transition: height $duration ease;
    -o-transition: height $duration ease;
    transition: height $duration ease;
}
