@import "../../../styles/variables";
@import "../../../styles/mixins";

.footerItemContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  cursor: pointer;
  color: $color-grey4;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;

  &:hover {
    color: $color-white;
    .sidebarIconPanel {
      .sidebarIcon {
        fill: $color-white;
      }
      .logoutIcon {
        fill: $color-white;
      }
    }
  }
}

.sidebarIconPanel {
  width: 100%;
  height: 26px;
  @include center-flex(flex);

  .sidebarIcon {
    height: 20px;
    fill: $color-grey4;
    transform: rotate(180deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }

  .supportIcon{
    height: 18px;
    fill: $color-grey4;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }

  .privacyPolicyIcon {
    height: 18px;
    fill: $color-grey4;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }

  .termsOfUseIcon {
    height: 15px;
    fill: $color-grey4;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }

  .logoutIcon {
    height: 15px;
    fill: $color-grey4;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
  }
}

.sidebarTextPanel {
  @include center-flex-y(flex);
  flex: 1 1;
  display: none;
  word-break: break-word;
  padding-right: 5px;
}

.sidebarIsOpen {

  & > .sidebarTextPanel {
    display: flex;
  }

  & > .sidebarIconPanel {
    width: 40px;

    & > .sidebarIcon {
      transform: rotate(0deg);
    }
  }
}
