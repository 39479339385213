@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.contentWrapper {
  width: 100%;
  max-height: 550px;
  overflow-y: scroll;
}

.content {
  height: 100%;
  width: 600px;
  color: $color-grey5;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.field {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  color: $color-black;
}

.label {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  color: $color-grey5;
}

.input {
  height: 40px;
  border: $border2;
  border-radius: 10px;
  padding: 0 15px;
}

.scheduleTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.scheduleTimeYear {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 390px;
}

.recipients {
  width: 100%;
  height: 100px;
  overflow: auto;
  background-color: $color-white;
  position: relative;
  border: $border2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
}

.recipientLine {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .recipient {
    color: $color-black;
  }

  .action {
    color: $color-action;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.recipientsAddLine {
  position: relative;
  width: 100%;
  border: $border2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: flex-end;

  .recipientsAddAction {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $color-action;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .recipientsAddActionInvalid {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: $color-grey5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.recipientInput {
  height: 40px;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 50px 0 15px;
  width: 100%;
}
