@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.menuButtonContainer {
  position: relative;
}

.menuButton {
  @include center-flex(flex);
  height: 100%;
  display: flex;
  justify-content: center;
  width: 160px;
  padding: 10px 0 0 10px;
  color: $color-grey5;

  &:hover {
    color: $color-blue6;
  }

  .iconPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    padding: 10px;

    .iconButton {
      @include center-flex(flex);
      cursor: pointer;
      height:50px;
      width: 50px;
    }
  }
}

.subMenu {
  @include transition-animation(0.2s);
  top: 60px;
  left: 0;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  overflow: hidden;

  &.isOpen {
    height: 125px;
  }
}

.submenuPanel {
  @include box-shadow(1);
  z-index: 10;
  background-color: $color-white;
  width: 160px;
  border: $border3;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.submenuItem {
  font-size: $font-size5;
  color: $color-black;
  font-weight: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 0 15px;

  &:hover {
    color: $color-blue5;
  }
}
