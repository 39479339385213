@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.content {
  padding: 0 30px;
}

.contentPanel {
  background-color: white;
  border: $border2;
  border-radius: 10px;
  min-height: 400px;
  max-width: 1400px;
  padding: 30px;
}

.loading {
  padding: 30px;
}

.topBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px;
}

.table {
  margin-top: 0;
  max-width: 1400px;
}

.rows {
  max-height: 550px;
  height: 550px;
  overflow: auto;
}

.row {
  height: 50px;
  display: flex;
  border-radius: 5px;
  border-bottom: 1px solid $color-grey1;
  align-items: center;
  padding: 0 20px;

  &:hover {
    background-color: $color-grey1;
  }

  &.header {
    font-weight: bolder;
    height: 40px;
    padding-right: 35px;
    background-color: $color-white;
  }

  &.isDisabled {
    opacity: 0.7;
    pointer-events: none
  }
}

.cell {
  flex: 1 1;
}

.centeredCell {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.buttons {
  display: flex;
  margin-top: 40px;
  padding: 0 30px;
}

.button {
  margin-right: 20px;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
