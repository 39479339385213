@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/global";

.title {
  padding: 20px 0 10px;
  color: $color-grey5;
}

.form {
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  max-height: 550px;
  overflow-y: scroll;

  .formRow {
    display: flex;
    width: 100%;

    .formColumn {
      width: 100%;
    }
  }
}

.info {
  height: 17px;
  margin-bottom: 20px;
}

.select {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
    margin: 8px 0;
  }
}

.fieldInput {
  background-color: $color-grey0;
  width: 100%;
  border-radius: 10px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  margin-top: 8px;
  padding: 9px 10px;
}

.textFilter{
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-left: 20px;
  margin-bottom: 26px;
}
