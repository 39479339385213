@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.tabsContainer {
  @include no-select();
  width: 100%;
  display: flex;
  padding: 0 30px;
  height: 100%;
  justify-content: space-between;
}

.tabs {
  display: flex;
  max-width: calc(100% - 160px);
}

.menuButtonContainer {
  position: relative;
}

.menuButton {
  @include center-flex(flex);
  height: 100%;
  display: flex;
  justify-content: center;
  width: 220px;
  padding: 10px 0 0 10px;
  cursor: pointer;
  color: $color-grey5;

  &:hover {
    color: $color-blue6;
  }

  .caption {
    @include text-ellipsis();
    max-width: 190px;
    text-align: center;
    flex-grow: 1;
  }
}

.tabItemActive {
  cursor: default;
  color: $color-blue6;
  font-weight: 600;
  border-bottom: 2px solid $color-blue6;
}
