@import "../../../styles/variables";
@import "../../../styles/mixins";

.searchContainer {
  @include center-flex(flex);
  min-width: 400px;
  max-width: 600px;
  padding: 0 30px;
  color: $color-grey4;

  .inputPanel {
    width: 100%;
    background-color: $color-grey0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: $border2;

    .searchInput {
      height: 40px;
      width: 90%;
      outline: none;
      border: none;
      background-color: $color-grey0;
      padding: 0 10px;
      font-size: $font-size6
    }
  }
}

.resultsContainer {
  z-index: 999;
  @include box-shadow(1);
  background-color: $color-white;
  width: 340px;
  border: $border3;
  border-radius: 10px;
  position: absolute;
  top: 70px;
  visibility: hidden;

  &.isOpen {
    visibility: visible;
  }
}

.searching {
  padding: 20px;
  border-top: $border3;
  text-align: center;
}

.resultsPanel {
  display: flex;
  flex-direction: column;
}

.showingResultsFor {
  padding: 20px;
  text-align: left;
  color: $color-black;
}

.lastSearchQuery {
  font-weight: bolder;
}

.showingThreshold {
  border-top: $border3;
  padding: 20px;
}
