@import "../../styles/variables";
@import "../../styles/global";
@import "../../styles/mixins";

.termsContainer {
  width: 100%;
}

.document {
  text-align: start;
}

.subTitle {
  font-size: $font-size6;
  font-weight: bolder;
  margin-bottom: 15px;
}

.paragraph {
  font-size: $font-size6;
  margin-bottom: 20px;
}

