@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.selectorContainer {
  display: flex;
  max-height: 100%;
}

.selectBox {
  @include center-flex(flex);
  padding-right: 15px;
  font-size: $font-size6;
}

.itemText {
  @include no-select();
  display: flex;
  flex-direction: column;
  height: 40px;
  flex-grow: 1;
  padding: 11px 0;
  color: $color-black;
}

.selectorLine {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-left: 30px;
}

.itemLine {
  margin-top: 1px;
  margin-bottom: 4px;
}

.questionIcon {
  @include center-flex(flex);
  color: $color-grey3;

  &:hover {
    color: $color-blue4;
    cursor: help;
  }
}
