@import "../../styles/variables";
@import "../../styles/global";
@import "../../styles/mixins";

.title {
  font-size: $font-size8;
  font-weight: bolder;
  margin-bottom: 30px;
}

.popupContainer {
  min-width: 300px;
  padding: 30px;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin: 10px 0;
}

.contactRow{
  width: 600px;
  display:flex;
  justify-content: space-between;
  margin: 5px 0;
}

.contact {
  width: 280px;
  margin: 8px 0;
}

.contactCountry{
  font-weight: 600;
}

.contactMail{
  margin: 5px 0 ;
}

.contactPhone{

}
.icon {
  margin-right: 10px;
}

.buttonsPanel {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
