@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.menuButtonContainer {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 140px;
  max-width: 200px;
}

.menuButton {
  @include center-flex(flex);
  height: 100%;
  width: 100%;
  padding: 10px 0 0 10px;
  cursor: pointer;
  color: $color-grey5;

  &:hover {
    color: $color-blue6;
  }

  .caption {
    @include text-ellipsis();
    max-width: 160px;
    text-align: center;
    flex-grow: 1;
  }

  .icon {
    visibility: hidden;
    padding: 10px;
    margin-left: 5px;
    border-radius: 5px;
  }
}

.tabItemActive {
  cursor: default;
  color: $color-blue6;
  font-weight: 600;
  border-bottom: 2px solid $color-blue6;

  .icon {
    color: $color-grey4;
    visibility: visible;
    cursor: pointer;
    outline: none;

    &:hover {
      background: $color-grey1;
      color: $color-blue4;
    }
  }
}

.subMenu {
  @include transition-animation(0.2s);
  top: 60px;
  left: 0;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  overflow: hidden;

  &.isOpen {
    height: 125px;
  }
}

.submenuPanel {
  @include box-shadow(1);
  z-index: 10;
  background-color: $color-white;
  width: 200px;
  border: $border3;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.submenuItem {
  font-size: $font-size5;
  color: $color-black;
  font-weight: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding: 0 30px;

  &:hover {
    color: $color-blue5;
  }
}
