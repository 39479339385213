@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/global";

.title {
  padding: 20px 0 10px;
  color: $color-grey5;
}

.form {
  background-color: $color-white;
  border: 1px solid $color-grey2;
  border-radius: 10px;
  padding: 20px;
  max-width: 1200px;
}

.info {
  margin-bottom: 20px;
}

.select {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  width: 500px;

  label {
    height: 35px;
  }
}
